// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var Js_exn = require("rescript/lib/js/js_exn.js");
var Js_dict = require("rescript/lib/js/js_dict.js");
var Js_json = require("rescript/lib/js/js_json.js");
var Core__Option = require("@rescript/core/src/Core__Option.bs.js");
var GraphQLConverter = require("../../common/GraphQLConverter.bs.js");

function makeActive(onMlsAt, willShow, willAcceptOffers) {
  return {
          TAG: "Active",
          _0: {
            onMlsAt: onMlsAt,
            willShow: willShow,
            willAcceptOffers: willAcceptOffers
          }
        };
}

function makeComingSoon(onMlsAt, willShow, willAcceptOffers) {
  return {
          TAG: "ComingSoon",
          _0: {
            onMlsAt: onMlsAt,
            willShow: willShow,
            willAcceptOffers: willAcceptOffers
          }
        };
}

function makeOffMls(willShow, willAcceptOffers) {
  return {
          TAG: "OffMls",
          _0: {
            willShow: willShow,
            willAcceptOffers: willAcceptOffers
          }
        };
}

function decodeWithDetails(builder, raw) {
  var func = GraphQLConverter.Converter.runConverter;
  var partial_arg = GraphQLConverter.Converter.some((function (obj) {
          return obj.willAcceptOffers;
        }), "willAcceptOffers not available");
  var partial_arg$1 = GraphQLConverter.Converter.some((function (obj) {
          return obj.willShow;
        }), "willShow not available");
  var partial_arg$2 = function (param) {
    return GraphQLConverter.Helpers.flatMap((function (param) {
                  return GraphQLConverter.Helpers.flatMap((function (param) {
                                return GraphQLConverter.Converter.source(raw, param);
                              }), (function (param) {
                                return GraphQLConverter.Converter.ok((function (obj) {
                                              return GraphQLConverter.Helpers.decodeRequired("onMlsAt was None", GraphQLConverter.Helpers.parseUTCDateFromFloat, obj.onMlsAt);
                                            }), param);
                              }), param);
                }), partial_arg$1, param);
  };
  return Curry._2(func, (function (param) {
                return GraphQLConverter.Helpers.flatMap(partial_arg$2, partial_arg, param);
              }), builder);
}

function decode(raw) {
  var match = raw.visibility;
  if (match === undefined) {
    return {
            TAG: "Error",
            _0: Curry._1(GraphQLConverter.Converter.$$Error.make, "Can't construct MlsPlans because visibility was none")
          };
  }
  if (match === "PUBLIC") {
    var match$1 = raw.mlsListingType;
    var match$2 = raw.goingOnMls;
    if (match$1 !== undefined) {
      if (match$1 === "COMING_SOON") {
        return decodeWithDetails(makeComingSoon, raw);
      }
      if (match$1 !== "OFF_MLS") {
        return decodeWithDetails(makeActive, raw);
      }
      
    } else {
      if (match$2 === undefined) {
        return {
                TAG: "Error",
                _0: Curry._1(GraphQLConverter.Converter.$$Error.make, "Can't construct MlsPlans because both mlsListingType and goingOnMls were none in a public property")
              };
      }
      if (match$2) {
        return decodeWithDetails(makeActive, raw);
      }
      
    }
    var willAcceptOffers = Core__Option.getOr(raw.willAcceptOffers, false);
    var willShow = Core__Option.getOr(raw.willShow, false);
    return {
            TAG: "Ok",
            _0: {
              TAG: "OffMls",
              _0: {
                willShow: willShow,
                willAcceptOffers: willAcceptOffers
              }
            }
          };
  }
  var willAcceptOffers$1 = Core__Option.getOr(raw.willAcceptOffers, false);
  var willShow$1 = Core__Option.getOr(raw.willShow, false);
  return {
          TAG: "Ok",
          _0: {
            TAG: "OffMls",
            _0: {
              willShow: willShow$1,
              willAcceptOffers: willAcceptOffers$1
            }
          }
        };
}

function decodeFromJs(obj) {
  return decode({
              mlsListingType: obj.mlsListingType,
              goingOnMls: obj.goingOnMls,
              onMlsAt: obj.onMlsAt,
              willShow: obj.willShow,
              willAcceptOffers: obj.willAcceptOffers,
              visibility: obj.visibility
            });
}

var ppx_printed_query = "fragment mlsPlans on Property   {\nmlsListingType  \ngoingOnMls  \nvisibility  \nwillShow  \nwillAcceptOffers  \nonMlsAt  \n}\n";

function parse(value) {
  var value$1 = Js_json.decodeObject(value);
  var tmp;
  if (value$1 !== undefined) {
    var value$2 = Js_dict.get(value$1, "mlsListingType");
    var field_mlsListingType;
    if (value$2 !== undefined && !(value$2 == null)) {
      var tmp$1;
      switch (value$2) {
        case "ACTIVE" :
            tmp$1 = "ACTIVE";
            break;
        case "COMING_SOON" :
            tmp$1 = "COMING_SOON";
            break;
        default:
          tmp$1 = "OFF_MLS";
      }
      field_mlsListingType = tmp$1;
    } else {
      field_mlsListingType = undefined;
    }
    var value$3 = Js_dict.get(value$1, "goingOnMls");
    var field_goingOnMls = value$3 !== undefined && !(value$3 == null) ? value$3 : undefined;
    var value$4 = Js_dict.get(value$1, "visibility");
    var field_visibility;
    if (value$4 !== undefined && !(value$4 == null)) {
      var tmp$2 = value$4 === "PRIVATE" ? "PRIVATE" : "PUBLIC";
      field_visibility = tmp$2;
    } else {
      field_visibility = undefined;
    }
    var value$5 = Js_dict.get(value$1, "willShow");
    var field_willShow = value$5 !== undefined && !(value$5 == null) ? value$5 : undefined;
    var value$6 = Js_dict.get(value$1, "willAcceptOffers");
    var field_willAcceptOffers = value$6 !== undefined && !(value$6 == null) ? value$6 : undefined;
    var value$7 = Js_dict.get(value$1, "onMlsAt");
    var field_onMlsAt = value$7 !== undefined && !(value$7 == null) ? value$7 : undefined;
    tmp = {
      mlsListingType: field_mlsListingType,
      goingOnMls: field_goingOnMls,
      onMlsAt: field_onMlsAt,
      willShow: field_willShow,
      willAcceptOffers: field_willAcceptOffers,
      visibility: field_visibility
    };
  } else {
    tmp = Js_exn.raiseError("Unexpected GraphQL query response");
  }
  return decode(tmp);
}

function ret_type(f) {
  return {};
}

var MT_Ret = {};

var MlsPlans = {
  ppx_printed_query: ppx_printed_query,
  query: ppx_printed_query,
  parse: parse,
  name: "mlsPlans",
  ret_type: ret_type,
  MT_Ret: MT_Ret
};

var Fragment;

exports.makeActive = makeActive;
exports.makeComingSoon = makeComingSoon;
exports.makeOffMls = makeOffMls;
exports.decodeWithDetails = decodeWithDetails;
exports.decode = decode;
exports.decodeFromJs = decodeFromJs;
exports.MlsPlans = MlsPlans;
exports.Fragment = Fragment;
/* GraphQLConverter Not a pure module */
