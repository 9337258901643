// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Caml = require("rescript/lib/js/caml.js");
var Js_exn = require("rescript/lib/js/js_exn.js");
var $$String = require("rescript/lib/js/string.js");
var Js_dict = require("rescript/lib/js/js_dict.js");
var Js_json = require("rescript/lib/js/js_json.js");

function mapCensusPlaceTypeToCityType(x) {
  if (x === "CENSUS_PLACE") {
    return "CITY";
  } else {
    return x;
  }
}

function areaTypeLabel(x) {
  if (x === "NEIGHBORHOOD") {
    return "Neighborhoods";
  } else if (x === "SCHOOL") {
    return "Schools";
  } else if (x === "COUNTY") {
    return "Counties";
  } else if (x === "STATE") {
    return "States";
  } else if (x === "TOWNSHIP") {
    return "Townships";
  } else if (x === "SCHOOL_DISTRICT") {
    return "School Districts";
  } else if (x === "BLOCK_GROUP") {
    return "Block Groups";
  } else if (x === "CITY") {
    return "Cities";
  } else {
    return "Zip Codes";
  }
}

var ppx_printed_query = "fragment AreaSummary on AreaSummary   {\nid  \nname  \nlabel  \ntype_: type  \nstate  \n}\n";

function parse(value) {
  var value$1 = Js_json.decodeObject(value);
  if (value$1 === undefined) {
    return Js_exn.raiseError("Unexpected GraphQL query response");
  }
  var value$2 = Js_dict.get(value$1, "id");
  var field_id = value$2 !== undefined ? value$2 : Js_exn.raiseError("Unexpected GraphQL query response");
  var value$3 = Js_dict.get(value$1, "name");
  var field_name = value$3 !== undefined ? value$3 : Js_exn.raiseError("Unexpected GraphQL query response");
  var value$4 = Js_dict.get(value$1, "label");
  var field_label = value$4 !== undefined ? value$4 : Js_exn.raiseError("Unexpected GraphQL query response");
  var value$5 = Js_dict.get(value$1, "type_");
  var field_type_;
  if (value$5 !== undefined) {
    var tmp;
    switch (value$5) {
      case "BLOCK_GROUP" :
          tmp = "BLOCK_GROUP";
          break;
      case "CENSUS_PLACE" :
          tmp = "CENSUS_PLACE";
          break;
      case "CITY" :
          tmp = "CITY";
          break;
      case "COUNTY" :
          tmp = "COUNTY";
          break;
      case "NEIGHBORHOOD" :
          tmp = "NEIGHBORHOOD";
          break;
      case "SCHOOL" :
          tmp = "SCHOOL";
          break;
      case "STATE" :
          tmp = "STATE";
          break;
      case "TOWNSHIP" :
          tmp = "TOWNSHIP";
          break;
      case "ZIP_CODE" :
          tmp = "ZIP_CODE";
          break;
      default:
        tmp = "SCHOOL_DISTRICT";
    }
    field_type_ = mapCensusPlaceTypeToCityType(tmp);
  } else {
    field_type_ = Js_exn.raiseError("Unexpected GraphQL query response");
  }
  var value$6 = Js_dict.get(value$1, "state");
  var field_state = value$6 !== undefined && !(value$6 == null) ? value$6 : undefined;
  return {
          id: field_id,
          name: field_name,
          label: field_label,
          type_: field_type_,
          state: field_state
        };
}

function ret_type(f) {
  return {};
}

var MT_Ret = {};

var AreaSummary = {
  ppx_printed_query: ppx_printed_query,
  query: ppx_printed_query,
  parse: parse,
  name: "AreaSummary",
  ret_type: ret_type,
  MT_Ret: MT_Ret
};

function typeSortOrder(x) {
  if (x === "NEIGHBORHOOD") {
    return 4;
  } else if (x === "SCHOOL") {
    return 7;
  } else if (x === "COUNTY") {
    return 1;
  } else if (x === "STATE") {
    return 0;
  } else if (x === "TOWNSHIP") {
    return 3;
  } else if (x === "SCHOOL_DISTRICT") {
    return 6;
  } else if (x === "BLOCK_GROUP") {
    return 8;
  } else if (x === "CITY") {
    return 2;
  } else {
    return 5;
  }
}

function compare(a, b) {
  var v = Caml.int_compare(typeSortOrder(a.type_), typeSortOrder(b.type_));
  if (v !== 0) {
    return v;
  } else {
    return $$String.compare(a.label, b.label);
  }
}

var Fragment;

exports.mapCensusPlaceTypeToCityType = mapCensusPlaceTypeToCityType;
exports.areaTypeLabel = areaTypeLabel;
exports.AreaSummary = AreaSummary;
exports.Fragment = Fragment;
exports.typeSortOrder = typeSortOrder;
exports.compare = compare;
/* No side effect */
