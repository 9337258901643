// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var Js_exn = require("rescript/lib/js/js_exn.js");
var Js_dict = require("rescript/lib/js/js_dict.js");
var Js_json = require("rescript/lib/js/js_json.js");
var Js_array = require("rescript/lib/js/js_array.js");
var MlsPlans = require("./MlsPlans.bs.js");
var DateFnsTz = require("../../common/DateFns/DateFnsTz.bs.js");
var ExpiryInfo = require("./ExpiryInfo.bs.js");
var AreaSummary = require("../graphql/AreaSummary.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Types__User = require("../../user/Types__User.bs.js");
var Core__Option = require("@rescript/core/src/Core__Option.bs.js");
var GraphQLHelpers = require("../../common/GraphQL/GraphQLHelpers.bs.js");
var GraphQLConverter = require("../../common/GraphQLConverter.bs.js");
var Types__Attachment = require("../../common/types/Types__Attachment.bs.js");
var StandardizedAddress = require("../graphql/StandardizedAddress.bs.js");
var Types__Clients__Contact = require("./Types__Clients__Contact.bs.js");

function defaultToFalse(__x) {
  return Core__Option.getOr(__x, false);
}

function defaultToTrue(__x) {
  return Core__Option.getOr(__x, true);
}

function decode(obj) {
  var match = obj.priceRange;
  if (match === undefined) {
    return {
            TAG: "Error",
            _0: GraphQLConverter.Helpers.makeError("priceRange was None")
          };
  }
  if (match) {
    var partial_arg = GraphQLConverter.Helpers.some(obj.maxPrice)("maxPrice was None");
    var partial_arg$1 = GraphQLConverter.Helpers.some(obj.minPrice)("minPrice was None");
    var partial_arg$2 = function (param) {
      return GraphQLConverter.Helpers.flatMap((function (param) {
                    return GraphQLConverter.Helpers.source(obj, param);
                  }), partial_arg$1, param);
    };
    return GraphQLConverter.Helpers.run(function (min, max) {
                  return {
                          NAME: "PriceRange",
                          VAL: [
                            min,
                            max
                          ]
                        };
                })(function (param) {
                return GraphQLConverter.Helpers.flatMap(partial_arg$2, partial_arg, param);
              });
  }
  var partial_arg$3 = GraphQLConverter.Helpers.some(obj.price)("price was None");
  return GraphQLConverter.Helpers.run(function (price) {
                return {
                        NAME: "Price",
                        VAL: price
                      };
              })(function (param) {
              return GraphQLConverter.Helpers.flatMap((function (param) {
                            return GraphQLConverter.Helpers.source(obj, param);
                          }), partial_arg$3, param);
            });
}

var ppx_printed_query = "fragment price on Property   {\npriceRange  \nprice  \nminPrice  \nmaxPrice  \n}\n";

function parse(value) {
  var value$1 = Js_json.decodeObject(value);
  var tmp;
  if (value$1 !== undefined) {
    var value$2 = Js_dict.get(value$1, "priceRange");
    var field_priceRange = value$2 !== undefined && !(value$2 == null) ? value$2 : undefined;
    var value$3 = Js_dict.get(value$1, "price");
    var field_price = value$3 !== undefined && !(value$3 == null) ? value$3 : undefined;
    var value$4 = Js_dict.get(value$1, "minPrice");
    var field_minPrice = value$4 !== undefined && !(value$4 == null) ? value$4 : undefined;
    var value$5 = Js_dict.get(value$1, "maxPrice");
    var field_maxPrice = value$5 !== undefined && !(value$5 == null) ? value$5 : undefined;
    tmp = {
      priceRange: field_priceRange,
      price: field_price,
      minPrice: field_minPrice,
      maxPrice: field_maxPrice
    };
  } else {
    tmp = Js_exn.raiseError("Unexpected GraphQL query response");
  }
  return decode(tmp);
}

var name = "price";

function ret_type(f) {
  return {};
}

var MT_Ret = {};

var Price = {
  ppx_printed_query: ppx_printed_query,
  query: ppx_printed_query,
  parse: parse,
  name: name,
  ret_type: ret_type,
  MT_Ret: MT_Ret
};

var Price$1 = {
  decode: decode,
  Price: Price,
  Fragment: undefined
};

function toString(x) {
  if (typeof x !== "object") {
    return "";
  } else {
    return x._0;
  }
}

function decode$1(obj) {
  if (Core__Option.getOr(obj.hiddenProperty, false)) {
    return {
            TAG: "Ok",
            _0: "HiddenPropertyDescription"
          };
  }
  var partial_arg = GraphQLConverter.Helpers.some(obj.description)("description was None");
  return GraphQLConverter.Helpers.run(function (description) {
                return {
                        TAG: "Description",
                        _0: description
                      };
              })(function (param) {
              return GraphQLConverter.Helpers.flatMap((function (param) {
                            return GraphQLConverter.Helpers.source(obj, param);
                          }), partial_arg, param);
            });
}

var ppx_printed_query$1 = "fragment description on Property   {\ndescription  \nhiddenProperty  \n}\n";

function parse$1(value) {
  var value$1 = Js_json.decodeObject(value);
  var tmp;
  if (value$1 !== undefined) {
    var value$2 = Js_dict.get(value$1, "description");
    var field_description = value$2 !== undefined && !(value$2 == null) ? value$2 : undefined;
    var value$3 = Js_dict.get(value$1, "hiddenProperty");
    var field_hiddenProperty = value$3 !== undefined && !(value$3 == null) ? value$3 : undefined;
    tmp = {
      description: field_description,
      hiddenProperty: field_hiddenProperty
    };
  } else {
    tmp = Js_exn.raiseError("Unexpected GraphQL query response");
  }
  return decode$1(tmp);
}

var name$1 = "description";

function ret_type$1(f) {
  return {};
}

var MT_Ret$1 = {};

var Description = {
  ppx_printed_query: ppx_printed_query$1,
  query: ppx_printed_query$1,
  parse: parse$1,
  name: name$1,
  ret_type: ret_type$1,
  MT_Ret: MT_Ret$1
};

var Description$1 = {
  toString: toString,
  decode: decode$1,
  Description: Description,
  Fragment: undefined
};

function decode$2(obj) {
  var match = obj.hideAddress;
  var match$1 = obj.address;
  if (match) {
    return {
            TAG: "Ok",
            _0: {
              NAME: "Private",
              VAL: match$1
            }
          };
  } else if (match$1 !== undefined) {
    return {
            TAG: "Ok",
            _0: {
              NAME: "Public",
              VAL: match$1
            }
          };
  } else {
    return {
            TAG: "Error",
            _0: GraphQLConverter.Helpers.makeError("public address was None")
          };
  }
}

var ppx_printed_query$2 = "fragment address on Property   {\naddress  \nhideAddress  \n}\n";

function parse$2(value) {
  var value$1 = Js_json.decodeObject(value);
  var tmp;
  if (value$1 !== undefined) {
    var value$2 = Js_dict.get(value$1, "address");
    var field_address = value$2 !== undefined && !(value$2 == null) ? value$2 : undefined;
    var value$3 = Js_dict.get(value$1, "hideAddress");
    var field_hideAddress = value$3 !== undefined ? Core__Option.getOr(!(value$3 == null) ? value$3 : undefined, true) : Js_exn.raiseError("Unexpected GraphQL query response");
    tmp = {
      address: field_address,
      hideAddress: field_hideAddress
    };
  } else {
    tmp = Js_exn.raiseError("Unexpected GraphQL query response");
  }
  return decode$2(tmp);
}

var name$2 = "address";

function ret_type$2(f) {
  return {};
}

var MT_Ret$2 = {};

var Address = {
  ppx_printed_query: ppx_printed_query$2,
  query: ppx_printed_query$2,
  parse: parse$2,
  name: name$2,
  ret_type: ret_type$2,
  MT_Ret: MT_Ret$2
};

function toString$1(x) {
  if (x.NAME === "Public") {
    return x.VAL;
  }
  var s = x.VAL;
  if (s !== undefined) {
    return s;
  } else {
    return "Hidden Address";
  }
}

var Address$1 = {
  decode: decode$2,
  Address: Address,
  Fragment: undefined,
  toString: toString$1
};

function decode$3(obj) {
  var match = obj.propertyType;
  if (match === undefined) {
    return {
            TAG: "Error",
            _0: GraphQLConverter.Helpers.makeError("propertyType was None")
          };
  }
  if (match === "LOT") {
    var partial_arg = GraphQLConverter.Helpers.some(obj.lotSize)("numberOfUnits was None");
    return GraphQLConverter.Helpers.run(function (lotSize) {
                  return {
                          TAG: "Lot",
                          _0: lotSize
                        };
                })(function (param) {
                return GraphQLConverter.Helpers.flatMap((function (param) {
                              return GraphQLConverter.Helpers.source(obj, param);
                            }), partial_arg, param);
              });
  }
  if (match === "MULTI_UNIT") {
    var partial_arg$1 = GraphQLConverter.Helpers.some(obj.numberOfUnits)("numberOfUnits was None");
    return GraphQLConverter.Helpers.run(function (n) {
                  return {
                          TAG: "MultiUnit",
                          _0: n
                        };
                })(function (param) {
                return GraphQLConverter.Helpers.flatMap((function (param) {
                              return GraphQLConverter.Helpers.source(obj, param);
                            }), partial_arg$1, param);
              });
  }
  var partial_arg$2 = GraphQLConverter.Helpers.some(obj.baths)("baths was None");
  var partial_arg$3 = GraphQLConverter.Helpers.some(obj.beds)("beds was None");
  var partial_arg$4 = function (param) {
    return GraphQLConverter.Helpers.flatMap((function (param) {
                  return GraphQLConverter.Helpers.source(obj, param);
                }), partial_arg$3, param);
  };
  return GraphQLConverter.Helpers.run(function (beds, baths) {
                if (match === "SINGLE_FAMILY") {
                  return {
                          TAG: "SingleFamily",
                          _0: {
                            beds: beds,
                            baths: baths,
                            halfBaths: obj.halfBaths,
                            livingArea: obj.livingArea,
                            lotSize: obj.lotSize
                          }
                        };
                } else {
                  return {
                          TAG: "Condo",
                          _0: {
                            beds: beds,
                            baths: baths,
                            halfBaths: obj.halfBaths,
                            livingArea: obj.livingArea,
                            lotSize: obj.lotSize
                          }
                        };
                }
              })(function (param) {
              return GraphQLConverter.Helpers.flatMap(partial_arg$4, partial_arg$2, param);
            });
}

var ppx_printed_query$3 = "fragment attributes on Property   {\npropertyType  \nbeds  \nbaths  \nhalfBaths  \nlivingArea  \nnumberOfUnits  \nlotSize  \n}\n";

function parse$3(value) {
  var value$1 = Js_json.decodeObject(value);
  var tmp;
  if (value$1 !== undefined) {
    var value$2 = Js_dict.get(value$1, "propertyType");
    var field_propertyType;
    if (value$2 !== undefined && !(value$2 == null)) {
      var tmp$1;
      switch (value$2) {
        case "CONDO" :
            tmp$1 = "CONDO";
            break;
        case "LOT" :
            tmp$1 = "LOT";
            break;
        case "MULTI_UNIT" :
            tmp$1 = "MULTI_UNIT";
            break;
        default:
          tmp$1 = "SINGLE_FAMILY";
      }
      field_propertyType = tmp$1;
    } else {
      field_propertyType = undefined;
    }
    var value$3 = Js_dict.get(value$1, "beds");
    var field_beds = value$3 !== undefined && !(value$3 == null) ? value$3 : undefined;
    var value$4 = Js_dict.get(value$1, "baths");
    var field_baths = value$4 !== undefined && !(value$4 == null) ? value$4 : undefined;
    var value$5 = Js_dict.get(value$1, "halfBaths");
    var field_halfBaths = value$5 !== undefined && !(value$5 == null) ? value$5 : undefined;
    var value$6 = Js_dict.get(value$1, "livingArea");
    var field_livingArea = value$6 !== undefined && !(value$6 == null) ? value$6 : undefined;
    var value$7 = Js_dict.get(value$1, "numberOfUnits");
    var field_numberOfUnits = value$7 !== undefined && !(value$7 == null) ? value$7 : undefined;
    var value$8 = Js_dict.get(value$1, "lotSize");
    var field_lotSize = value$8 !== undefined && !(value$8 == null) ? value$8 : undefined;
    tmp = {
      propertyType: field_propertyType,
      beds: field_beds,
      baths: field_baths,
      halfBaths: field_halfBaths,
      livingArea: field_livingArea,
      numberOfUnits: field_numberOfUnits,
      lotSize: field_lotSize
    };
  } else {
    tmp = Js_exn.raiseError("Unexpected GraphQL query response");
  }
  return decode$3(tmp);
}

var name$3 = "attributes";

function ret_type$3(f) {
  return {};
}

var MT_Ret$3 = {};

var Attributes = {
  ppx_printed_query: ppx_printed_query$3,
  query: ppx_printed_query$3,
  parse: parse$3,
  name: name$3,
  ret_type: ret_type$3,
  MT_Ret: MT_Ret$3
};

function beds(x) {
  switch (x.TAG) {
    case "SingleFamily" :
    case "Condo" :
        return x._0.beds;
    case "MultiUnit" :
    case "Lot" :
        return ;
    
  }
}

function baths(x) {
  switch (x.TAG) {
    case "SingleFamily" :
    case "Condo" :
        return x._0.baths;
    case "MultiUnit" :
    case "Lot" :
        return ;
    
  }
}

function halfBaths(x) {
  switch (x.TAG) {
    case "SingleFamily" :
    case "Condo" :
        return x._0.halfBaths;
    case "MultiUnit" :
    case "Lot" :
        return ;
    
  }
}

function livingArea(x) {
  switch (x.TAG) {
    case "SingleFamily" :
    case "Condo" :
        return x._0.livingArea;
    case "MultiUnit" :
    case "Lot" :
        return ;
    
  }
}

function numberOfUnits(x) {
  switch (x.TAG) {
    case "MultiUnit" :
        return x._0;
    case "SingleFamily" :
    case "Condo" :
    case "Lot" :
        return ;
    
  }
}

function lotSize(x) {
  switch (x.TAG) {
    case "SingleFamily" :
    case "Condo" :
        return x._0.lotSize;
    case "MultiUnit" :
        return ;
    case "Lot" :
        return x._0;
    
  }
}

var Attributes$1 = {
  decode: decode$3,
  Attributes: Attributes,
  Fragment: undefined,
  beds: beds,
  baths: baths,
  halfBaths: halfBaths,
  livingArea: livingArea,
  numberOfUnits: numberOfUnits,
  lotSize: lotSize
};

function toString$2(x) {
  var dn = x.displayNeighborhood;
  var city = x.city;
  if (dn !== undefined) {
    return dn + ", " + city;
  } else {
    return city + ", " + x.zip;
  }
}

function make(stdAddress, userSelectedArea, displayNeighborhood) {
  var match = userSelectedArea.type_;
  if (match === "ZIP_CODE") {
    return {
            city: stdAddress.city,
            zip: userSelectedArea.name,
            displayNeighborhood: Core__Option.map(displayNeighborhood, (function (param) {
                    return param.name;
                  }))
          };
  } else if (match === "CITY") {
    return {
            city: userSelectedArea.name,
            zip: stdAddress.zip,
            displayNeighborhood: Core__Option.map(displayNeighborhood, (function (param) {
                    return param.name;
                  }))
          };
  } else {
    return {
            city: stdAddress.city,
            zip: stdAddress.zip,
            displayNeighborhood: Core__Option.map(displayNeighborhood, (function (param) {
                    return param.name;
                  }))
          };
  }
}

function decode$4(property) {
  var partial_arg = GraphQLConverter.Helpers.some(property.userSelectedArea)("missing userSelectedArea");
  var partial_arg$1 = GraphQLConverter.Helpers.ok(GraphQLConverter.Helpers.decodeRequired("standardizedAddress was None", (function (i) {
              return i;
            }), property.standardizedAddress));
  var partial_arg$2 = function (param) {
    return GraphQLConverter.Helpers.flatMap((function (param) {
                  return GraphQLConverter.Helpers.source(property, param);
                }), partial_arg$1, param);
  };
  return GraphQLConverter.Helpers.run(function (add, area) {
                return make(add, area, property.displayNeighborhood);
              })(function (param) {
              return GraphQLConverter.Helpers.flatMap(partial_arg$2, partial_arg, param);
            });
}

var ppx_printed_query$4 = "fragment PropertyDisplayLocation on Property   {\nuserSelectedArea  {\n..." + AreaSummary.AreaSummary.name + "   \n}\n\ndisplayNeighborhood  {\n..." + AreaSummary.AreaSummary.name + "   \n}\n\nstandardizedAddress  {\n..." + StandardizedAddress.Fragments.StandardizedAddress.name + "   \n}\n\n}\n" + AreaSummary.AreaSummary.query + StandardizedAddress.Fragments.StandardizedAddress.query;

function parse$4(value) {
  var value$1 = Js_json.decodeObject(value);
  var tmp;
  if (value$1 !== undefined) {
    var value$2 = Js_dict.get(value$1, "userSelectedArea");
    var field_userSelectedArea = value$2 !== undefined && !(value$2 == null) ? AreaSummary.AreaSummary.parse(value$2) : undefined;
    var value$3 = Js_dict.get(value$1, "displayNeighborhood");
    var field_displayNeighborhood = value$3 !== undefined && !(value$3 == null) ? AreaSummary.AreaSummary.parse(value$3) : undefined;
    var value$4 = Js_dict.get(value$1, "standardizedAddress");
    var field_standardizedAddress = value$4 !== undefined && !(value$4 == null) ? Curry._1(StandardizedAddress.Fragments.StandardizedAddress.parse, value$4) : undefined;
    tmp = {
      userSelectedArea: field_userSelectedArea,
      displayNeighborhood: field_displayNeighborhood,
      standardizedAddress: field_standardizedAddress
    };
  } else {
    tmp = Js_exn.raiseError("Unexpected GraphQL query response");
  }
  return decode$4(tmp);
}

var name$4 = "PropertyDisplayLocation";

function ret_type$4(f) {
  return {};
}

var MT_Ret$4 = {};

var PropertyDisplayLocation = {
  ppx_printed_query: ppx_printed_query$4,
  query: ppx_printed_query$4,
  parse: parse$4,
  name: name$4,
  ret_type: ret_type$4,
  MT_Ret: MT_Ret$4
};

var DisplayLocation = {
  toString: toString$2,
  make: make,
  decode: decode$4,
  PropertyDisplayLocation: PropertyDisplayLocation,
  Fragment: undefined
};

function fromProperty(status, expiryInfo, soldPrice, param) {
  if (status === "WITHDRAWN") {
    return "Withdrawn";
  } else if (status === "REMOVED") {
    return "Removed";
  } else if (status === "UNDER_CONTRACT") {
    return "UnderContract";
  } else if (status === "EXPIRED") {
    return "Expired";
  } else if (status === "SOLD") {
    return {
            TAG: "Sold",
            _0: soldPrice
          };
  } else if (ExpiryInfo.aboutToExpire(expiryInfo)) {
    return {
            TAG: "Expiring",
            _0: ExpiryInfo.daysToExpiration(expiryInfo)
          };
  } else {
    return "Active";
  }
}

function fromPropertyWithoutExpiring(status, soldPrice, param) {
  if (status === "WITHDRAWN") {
    return "Withdrawn";
  } else if (status === "REMOVED") {
    return "Removed";
  } else if (status === "UNDER_CONTRACT") {
    return "UnderContract";
  } else if (status === "EXPIRED") {
    return "Expired";
  } else if (status === "SOLD") {
    return {
            TAG: "Sold",
            _0: soldPrice
          };
  } else {
    return "Active";
  }
}

function toStatusT(x) {
  if (typeof x === "object") {
    if (x.TAG === "Expiring") {
      return {
              NAME: "EXPIRING",
              VAL: x._0
            };
    } else {
      return {
              NAME: "SOLD",
              VAL: x._0
            };
    }
  }
  switch (x) {
    case "Active" :
        return "ACTIVE";
    case "Expired" :
        return "EXPIRED";
    case "Withdrawn" :
        return "WITHDRAWN";
    case "Removed" :
        return "REMOVED";
    case "UnderContract" :
        return "UNDER_CONTRACT";
    
  }
}

var ListingStatus = {
  fromProperty: fromProperty,
  fromPropertyWithoutExpiring: fromPropertyWithoutExpiring,
  toStatusT: toStatusT
};

function isRemoved(v) {
  var match = v.listingStatus;
  if (typeof match !== "object" && match === "Removed") {
    return true;
  } else {
    return false;
  }
}

function decode$5(obj) {
  var partial_arg = GraphQLConverter.Helpers.someOk(obj.expiryInfo, "expiryInfo was None");
  var partial_arg$1 = GraphQLConverter.Helpers.ok(GraphQLConverter.Helpers.decodeOption(GraphQLConverter.Helpers.parseUTCDateFromFloat, obj.statusChangeAt));
  var partial_arg$2 = GraphQLConverter.Helpers.ok(GraphQLConverter.Helpers.decodeOption(GraphQLConverter.Helpers.parseUTCDateFromFloat, obj.broadcastAt));
  var partial_arg$3 = GraphQLConverter.Helpers.ok(GraphQLConverter.Helpers.decodeRequired("updatedAt was None", GraphQLConverter.Helpers.parseUTCDateFromFloat, obj.updatedAt));
  var partial_arg$4 = GraphQLConverter.Helpers.ok(GraphQLConverter.Helpers.decodeRequired("createdAt was None", GraphQLConverter.Helpers.parseUTCDateFromFloat, obj.createdAt));
  var match = obj.propertyStatus;
  var match$1 = obj.soldPrice;
  var partial_arg$5 = GraphQLConverter.Helpers.$$const(match !== undefined && match === "SOLD" && match$1 !== undefined ? match$1 : undefined);
  var partial_arg$6 = GraphQLConverter.Helpers.some(obj.propertyStatus)("propertyStatus was None");
  var partial_arg$7 = function (param) {
    return GraphQLConverter.Helpers.flatMap((function (param) {
                  return GraphQLConverter.Helpers.source(obj, param);
                }), partial_arg$6, param);
  };
  var partial_arg$8 = function (param) {
    return GraphQLConverter.Helpers.flatMap(partial_arg$7, partial_arg$5, param);
  };
  var partial_arg$9 = function (param) {
    return GraphQLConverter.Helpers.flatMap(partial_arg$8, partial_arg$4, param);
  };
  var partial_arg$10 = function (param) {
    return GraphQLConverter.Helpers.flatMap(partial_arg$9, partial_arg$3, param);
  };
  var partial_arg$11 = function (param) {
    return GraphQLConverter.Helpers.flatMap(partial_arg$10, partial_arg$2, param);
  };
  var partial_arg$12 = function (param) {
    return GraphQLConverter.Helpers.flatMap(partial_arg$11, partial_arg$1, param);
  };
  return GraphQLConverter.Helpers.run(function (status, soldPrice, createdAt, updatedAt, broadcastAt, statusChangeAt, expiryInfo) {
                return {
                        listingStatus: fromProperty(status, expiryInfo, soldPrice, undefined),
                        createdAt: createdAt,
                        updatedAt: updatedAt,
                        broadcastAt: broadcastAt,
                        statusChange: statusChangeAt !== undefined ? ({
                              TAG: "StatusChange",
                              _0: {
                                changeAt: Caml_option.valFromOption(statusChangeAt),
                                comment: obj.statusChangeComment,
                                hideSoldPrice: status === "SOLD" ? obj.hideSoldPrice : undefined
                              }
                            }) : "NoChange",
                        expiryInfo: expiryInfo
                      };
              })(function (param) {
              return GraphQLConverter.Helpers.flatMap(partial_arg$12, partial_arg, param);
            });
}

var ppx_printed_query$5 = "fragment propertyStatus on Property   {\npropertyStatus: status  \ncreatedAt  \nupdatedAt  \nbroadcastAt  \nstatusChangeComment  \nstatusChangeAt  \nsoldPrice  \nhideSoldPrice  \nexpiryInfo  {\n..." + ExpiryInfo.Fragment.name + "   \n}\n\n}\n" + ExpiryInfo.Fragment.query;

function parse$5(value) {
  var value$1 = Js_json.decodeObject(value);
  var tmp;
  if (value$1 !== undefined) {
    var value$2 = Js_dict.get(value$1, "propertyStatus");
    var field_propertyStatus;
    if (value$2 !== undefined && !(value$2 == null)) {
      var tmp$1;
      switch (value$2) {
        case "ACTIVE" :
            tmp$1 = "ACTIVE";
            break;
        case "EXPIRED" :
            tmp$1 = "EXPIRED";
            break;
        case "REMOVED" :
            tmp$1 = "REMOVED";
            break;
        case "SOLD" :
            tmp$1 = "SOLD";
            break;
        case "WITHDRAWN" :
            tmp$1 = "WITHDRAWN";
            break;
        default:
          tmp$1 = "UNDER_CONTRACT";
      }
      field_propertyStatus = tmp$1;
    } else {
      field_propertyStatus = undefined;
    }
    var value$3 = Js_dict.get(value$1, "createdAt");
    var field_createdAt = value$3 !== undefined && !(value$3 == null) ? value$3 : undefined;
    var value$4 = Js_dict.get(value$1, "updatedAt");
    var field_updatedAt = value$4 !== undefined && !(value$4 == null) ? value$4 : undefined;
    var value$5 = Js_dict.get(value$1, "broadcastAt");
    var field_broadcastAt = value$5 !== undefined && !(value$5 == null) ? value$5 : undefined;
    var value$6 = Js_dict.get(value$1, "statusChangeComment");
    var field_statusChangeComment = value$6 !== undefined && !(value$6 == null) ? value$6 : undefined;
    var value$7 = Js_dict.get(value$1, "statusChangeAt");
    var field_statusChangeAt = value$7 !== undefined && !(value$7 == null) ? value$7 : undefined;
    var value$8 = Js_dict.get(value$1, "soldPrice");
    var field_soldPrice = value$8 !== undefined && !(value$8 == null) ? value$8 : undefined;
    var value$9 = Js_dict.get(value$1, "hideSoldPrice");
    var field_hideSoldPrice = value$9 !== undefined && !(value$9 == null) ? value$9 : undefined;
    var value$10 = Js_dict.get(value$1, "expiryInfo");
    var field_expiryInfo = value$10 !== undefined && !(value$10 == null) ? ExpiryInfo.Fragment.parse(value$10) : undefined;
    tmp = {
      createdAt: field_createdAt,
      updatedAt: field_updatedAt,
      broadcastAt: field_broadcastAt,
      statusChangeAt: field_statusChangeAt,
      expiryInfo: field_expiryInfo,
      propertyStatus: field_propertyStatus,
      statusChangeComment: field_statusChangeComment,
      soldPrice: field_soldPrice,
      hideSoldPrice: field_hideSoldPrice
    };
  } else {
    tmp = Js_exn.raiseError("Unexpected GraphQL query response");
  }
  return decode$5(tmp);
}

var name$5 = "propertyStatus";

function ret_type$5(f) {
  return {};
}

var MT_Ret$5 = {};

var PropertyStatus = {
  ppx_printed_query: ppx_printed_query$5,
  query: ppx_printed_query$5,
  parse: parse$5,
  name: name$5,
  ret_type: ret_type$5,
  MT_Ret: MT_Ret$5
};

var Status = {
  ListingStatus: ListingStatus,
  isRemoved: isRemoved,
  decode: decode$5,
  PropertyStatus: PropertyStatus,
  Fragment: undefined
};

function decode$6(obj) {
  return GraphQLConverter.Helpers.decodeRequired("chapter was None", (function (chapter) {
                return GraphQLConverter.Helpers.decodeRequired("timeZone was None", (function (v) {
                              return {
                                      TAG: "Ok",
                                      _0: DateFnsTz.Timezone.make(v)
                                    };
                            }), chapter.timeZone);
              }), obj.chapter);
}

var ppx_printed_query$6 = "fragment PropertyTimeZone on Property   {\nid  \nchapter  {\nid  \ntimeZone  \n}\n\n}\n";

function parse$6(value) {
  var value$1 = value["chapter"];
  var tmp;
  if (value$1 == null) {
    tmp = undefined;
  } else {
    var value$2 = value$1["timeZone"];
    tmp = {
      id: value$1["id"],
      timeZone: !(value$2 == null) ? value$2 : undefined
    };
  }
  return decode$6({
              id: value["id"],
              chapter: tmp
            });
}

var name$6 = "PropertyTimeZone";

function ret_type$6(f) {
  return {};
}

var MT_Ret$6 = {};

var PropertyTimeZone = {
  ppx_printed_query: ppx_printed_query$6,
  query: ppx_printed_query$6,
  parse: parse$6,
  name: name$6,
  ret_type: ret_type$6,
  MT_Ret: MT_Ret$6
};

var TimeZone = {
  decode: decode$6,
  PropertyTimeZone: PropertyTimeZone,
  Fragment: undefined
};

function decode$7(obj) {
  var match = obj.hiddenProperty;
  var match$1 = obj.anonymousMatching;
  if (match) {
    return "Hidden";
  } else if (match$1) {
    return "Anonymous";
  } else {
    return "Normal";
  }
}

var ppx_printed_query$7 = "fragment PropertyPrivacySetting on Property   {\nhiddenProperty  \nanonymousMatching  \n}\n";

function parse$7(value) {
  var value$1 = value["hiddenProperty"];
  var value$2 = value["anonymousMatching"];
  return decode$7({
              hiddenProperty: GraphQLHelpers.defaultToFalse(!(value$1 == null) ? value$1 : undefined),
              anonymousMatching: GraphQLHelpers.defaultToFalse(!(value$2 == null) ? value$2 : undefined)
            });
}

var name$7 = "PropertyPrivacySetting";

function ret_type$7(f) {
  return {};
}

var MT_Ret$7 = {};

var PropertyPrivacySetting = {
  ppx_printed_query: ppx_printed_query$7,
  query: ppx_printed_query$7,
  parse: parse$7,
  name: name$7,
  ret_type: ret_type$7,
  MT_Ret: MT_Ret$7
};

var PrivacySetting = {
  decode: decode$7,
  PropertyPrivacySetting: PropertyPrivacySetting,
  Fragment: undefined
};

function decodeImages(param) {
  return GraphQLConverter.Helpers.decodeRequired("images was None", (function (param) {
                return GraphQLConverter.Helpers.decodeArray((function (param) {
                              return GraphQLConverter.Helpers.decodeRequired("image was None", Types__Attachment.decode, param);
                            }), param);
              }), param);
}

function make$1(id, address, $$location, attributes, price, agent, status, mlsPlans, activeOnMls, description, visibility, anonymousMatching, privacySetting, timeZone, images) {
  return {
          id: id,
          visibility: visibility,
          address: address,
          location: $$location,
          attributes: attributes,
          price: price,
          agent: agent,
          status: status,
          mlsPlans: mlsPlans,
          activeOnMls: activeOnMls,
          description: description,
          timeZone: timeZone,
          images: images,
          anonymousMatching: anonymousMatching,
          privacySetting: privacySetting
        };
}

function decode$8(obj) {
  var partial_arg = obj.id;
  var partial_arg$1 = GraphQLConverter.Helpers.ok(obj.images);
  var partial_arg$2 = GraphQLConverter.Helpers.ok(obj.timeZone);
  var partial_arg$3 = GraphQLConverter.Helpers.$$const(obj.privacySetting);
  var partial_arg$4 = GraphQLConverter.Helpers.$$const(obj.anonymousMatching);
  var partial_arg$5 = GraphQLConverter.Helpers.some(obj.visibility)("visibility was None");
  var partial_arg$6 = GraphQLConverter.Helpers.ok(obj.description);
  var match = obj.visibility;
  var match$1 = obj.activeOnMls;
  var tmp;
  var exit = 0;
  if (match === "PRIVATE") {
    tmp = {
      TAG: "Ok",
      _0: false
    };
  } else {
    exit = 1;
  }
  if (exit === 1) {
    tmp = match$1 !== undefined ? ({
          TAG: "Ok",
          _0: match$1
        }) : ({
          TAG: "Error",
          _0: GraphQLConverter.Helpers.makeError("activeOnMls was None")
        });
  }
  var partial_arg$7 = GraphQLConverter.Helpers.ok(tmp);
  var partial_arg$8 = GraphQLConverter.Helpers.ok(obj.mlsPlans);
  var partial_arg$9 = GraphQLConverter.Helpers.ok(obj.status);
  var partial_arg$10 = GraphQLConverter.Helpers.ok(obj.agent);
  var partial_arg$11 = GraphQLConverter.Helpers.ok(obj.price);
  var partial_arg$12 = GraphQLConverter.Helpers.ok(obj.attributes);
  var partial_arg$13 = GraphQLConverter.Helpers.ok(obj.location);
  var partial_arg$14 = GraphQLConverter.Helpers.ok(obj.address);
  var partial_arg$15 = function (param) {
    return GraphQLConverter.Helpers.flatMap((function (param) {
                  return GraphQLConverter.Helpers.source(obj, param);
                }), partial_arg$14, param);
  };
  var partial_arg$16 = function (param) {
    return GraphQLConverter.Helpers.flatMap(partial_arg$15, partial_arg$13, param);
  };
  var partial_arg$17 = function (param) {
    return GraphQLConverter.Helpers.flatMap(partial_arg$16, partial_arg$12, param);
  };
  var partial_arg$18 = function (param) {
    return GraphQLConverter.Helpers.flatMap(partial_arg$17, partial_arg$11, param);
  };
  var partial_arg$19 = function (param) {
    return GraphQLConverter.Helpers.flatMap(partial_arg$18, partial_arg$10, param);
  };
  var partial_arg$20 = function (param) {
    return GraphQLConverter.Helpers.flatMap(partial_arg$19, partial_arg$9, param);
  };
  var partial_arg$21 = function (param) {
    return GraphQLConverter.Helpers.flatMap(partial_arg$20, partial_arg$8, param);
  };
  var partial_arg$22 = function (param) {
    return GraphQLConverter.Helpers.flatMap(partial_arg$21, partial_arg$7, param);
  };
  var partial_arg$23 = function (param) {
    return GraphQLConverter.Helpers.flatMap(partial_arg$22, partial_arg$6, param);
  };
  var partial_arg$24 = function (param) {
    return GraphQLConverter.Helpers.flatMap(partial_arg$23, partial_arg$5, param);
  };
  var partial_arg$25 = function (param) {
    return GraphQLConverter.Helpers.flatMap(partial_arg$24, partial_arg$4, param);
  };
  var partial_arg$26 = function (param) {
    return GraphQLConverter.Helpers.flatMap(partial_arg$25, partial_arg$3, param);
  };
  var partial_arg$27 = function (param) {
    return GraphQLConverter.Helpers.flatMap(partial_arg$26, partial_arg$2, param);
  };
  return GraphQLConverter.Helpers.run(function (param, param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8, param$9, param$10, param$11, param$12, param$13) {
                return make$1(partial_arg, param, param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8, param$9, param$10, param$11, param$12, param$13);
              })(function (param) {
              return GraphQLConverter.Helpers.flatMap(partial_arg$27, partial_arg$1, param);
            });
}

var ppx_printed_query$8 = "fragment basicProperty on Property   {\nid  \n...address   \n...PropertyDisplayLocation   \n...attributes   \n...price   \n...propertyStatus   \n..." + MlsPlans.MlsPlans.name + "   \nactiveOnMls  \nagent  {\n..." + Types__User.Fragment.User.name + "   \n}\n\n..." + name$1 + "   \nvisibility  \nanonymousMatching  \n..." + name$7 + "   \nimages  {\n..." + Types__Attachment.Fragment.Attachment.name + "   \n}\n\n..." + name$6 + "   \n}\n" + ppx_printed_query$2 + ppx_printed_query$3 + ppx_printed_query$1 + ppx_printed_query$4 + MlsPlans.MlsPlans.query + ppx_printed_query + ppx_printed_query$7 + ppx_printed_query$5 + ppx_printed_query$6 + Types__Attachment.Fragment.Attachment.query + Types__User.Fragment.User.query;

function parse$8(value) {
  var value$1 = Js_json.decodeObject(value);
  var tmp;
  if (value$1 !== undefined) {
    var value$2 = Js_dict.get(value$1, "id");
    var field_id = value$2 !== undefined ? value$2 : Js_exn.raiseError("Unexpected GraphQL query response");
    var value$3 = Js_dict.get(value$1, "activeOnMls");
    var field_activeOnMls = value$3 !== undefined && !(value$3 == null) ? value$3 : undefined;
    var value$4 = Js_dict.get(value$1, "agent");
    var field_agent = value$4 !== undefined ? Types__User.decodeMaybeAnonymousUser(!(value$4 == null) ? Curry._1(Types__User.Fragment.User.parse, value$4) : undefined) : Js_exn.raiseError("Unexpected GraphQL query response");
    var value$5 = Js_dict.get(value$1, "visibility");
    var field_visibility;
    if (value$5 !== undefined && !(value$5 == null)) {
      var tmp$1 = value$5 === "PRIVATE" ? "PRIVATE" : "PUBLIC";
      field_visibility = tmp$1;
    } else {
      field_visibility = undefined;
    }
    var value$6 = Js_dict.get(value$1, "anonymousMatching");
    var field_anonymousMatching = value$6 !== undefined ? GraphQLHelpers.defaultToFalse(!(value$6 == null) ? value$6 : undefined) : Js_exn.raiseError("Unexpected GraphQL query response");
    var value$7 = Js_dict.get(value$1, "images");
    var field_images = value$7 !== undefined ? decodeImages(!(value$7 == null) ? Js_array.map((function (value) {
                    if (!(value == null)) {
                      return Curry._1(Types__Attachment.Fragment.Attachment.parse, value);
                    }
                    
                  }), value$7) : undefined) : Js_exn.raiseError("Unexpected GraphQL query response");
    tmp = {
      id: field_id,
      address: parse$2(value$1),
      location: parse$4(value$1),
      attributes: parse$3(value$1),
      price: parse(value$1),
      status: parse$5(value$1),
      mlsPlans: MlsPlans.MlsPlans.parse(value$1),
      activeOnMls: field_activeOnMls,
      agent: field_agent,
      description: parse$1(value$1),
      visibility: field_visibility,
      anonymousMatching: field_anonymousMatching,
      privacySetting: parse$7(value$1),
      images: field_images,
      timeZone: parse$6(value$1)
    };
  } else {
    tmp = Js_exn.raiseError("Unexpected GraphQL query response");
  }
  return decode$8(tmp);
}

function ret_type$8(f) {
  return {};
}

var MT_Ret$8 = {};

var BasicProperty = {
  ppx_printed_query: ppx_printed_query$8,
  query: ppx_printed_query$8,
  parse: parse$8,
  name: "basicProperty",
  ret_type: ret_type$8,
  MT_Ret: MT_Ret$8
};

var Basic = {
  decodeImages: decodeImages,
  make: make$1,
  decode: decode$8,
  BasicProperty: BasicProperty,
  Fragment: undefined
};

function decode$9(obj) {
  var primaryContact = Core__Option.flatMap(Core__Option.flatMap(obj.client, (function (client) {
              return client.primaryContact;
            })), (function (contact) {
          return Types__Clients__Contact.name_of_contact(contact.firstName, contact.lastName);
        }));
  return Core__Option.getOr(primaryContact, Core__Option.mapOr(obj.address, "Seller", (function (address) {
                    return "Seller at " + address;
                  })));
}

var ppx_printed_query$9 = "fragment PropertyTitle on Property   {\nid  \naddress  \nclient  {\nid  \nprimaryContact  {\nid  \nfirstName  \nlastName  \n}\n\n}\n\n}\n";

function parse$9(value) {
  var value$1 = value["address"];
  var value$2 = value["client"];
  var tmp;
  if (value$2 == null) {
    tmp = undefined;
  } else {
    var value$3 = value$2["primaryContact"];
    var tmp$1;
    if (value$3 == null) {
      tmp$1 = undefined;
    } else {
      var value$4 = value$3["firstName"];
      var value$5 = value$3["lastName"];
      tmp$1 = {
        id: value$3["id"],
        firstName: !(value$4 == null) ? value$4 : undefined,
        lastName: !(value$5 == null) ? value$5 : undefined
      };
    }
    tmp = {
      id: value$2["id"],
      primaryContact: tmp$1
    };
  }
  return decode$9({
              id: value["id"],
              address: !(value$1 == null) ? value$1 : undefined,
              client: tmp
            });
}

function ret_type$9(f) {
  return {};
}

var MT_Ret$9 = {};

var PropertyTitle = {
  ppx_printed_query: ppx_printed_query$9,
  query: ppx_printed_query$9,
  parse: parse$9,
  name: "PropertyTitle",
  ret_type: ret_type$9,
  MT_Ret: MT_Ret$9
};

var PropertyTitle$1 = {
  decode: decode$9,
  PropertyTitle: PropertyTitle,
  Fragment: undefined
};

exports.defaultToFalse = defaultToFalse;
exports.defaultToTrue = defaultToTrue;
exports.Price = Price$1;
exports.Description = Description$1;
exports.Address = Address$1;
exports.Attributes = Attributes$1;
exports.DisplayLocation = DisplayLocation;
exports.Status = Status;
exports.TimeZone = TimeZone;
exports.PrivacySetting = PrivacySetting;
exports.Basic = Basic;
exports.PropertyTitle = PropertyTitle$1;
/* MlsPlans Not a pure module */
