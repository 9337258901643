// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';


function name_of_contact(first_name, last_name) {
  if (first_name !== undefined) {
    if (last_name !== undefined) {
      return first_name + (" " + last_name);
    } else {
      return first_name;
    }
  } else if (last_name !== undefined) {
    return last_name;
  } else {
    return ;
  }
}

function displayName(param) {
  var lastName = param.lastName;
  var firstName = param.firstName;
  if (firstName !== undefined) {
    if (lastName !== undefined) {
      return firstName + (" " + lastName);
    } else {
      return firstName;
    }
  } else if (lastName !== undefined) {
    return lastName;
  } else {
    return ;
  }
}

exports.name_of_contact = name_of_contact;
exports.displayName = displayName;
/* No side effect */
