// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Js_exn = require("rescript/lib/js/js_exn.js");
var Js_dict = require("rescript/lib/js/js_dict.js");
var Js_json = require("rescript/lib/js/js_json.js");
var TanDate = require("../../common/types/TanDate.bs.js");
var UTCDate = require("../../common/types/UTCDate.bs.js");
var DateFns = require("date-fns");
var Core__Option = require("@rescript/core/src/Core__Option.bs.js");
var GraphQLConverter = require("../../common/GraphQLConverter.bs.js");

function expiresAt(t) {
  return t.expiresAt;
}

function activePeriodLength(t) {
  return t.activePeriodLength;
}

function make(expiresAt, activePeriodLength) {
  return {
          expiresAt: expiresAt,
          activePeriodLength: activePeriodLength
        };
}

function daysToExpiration(t) {
  return DateFns.differenceInDays(DateFns.startOfDay(UTCDate.toUnzonedDate(t.expiresAt)), DateFns.startOfDay(TanDate.now())) | 0;
}

function aboutToExpire(t) {
  var numberOfDays = t.activePeriodLength > 15 ? 7 : 3;
  return daysToExpiration(t) <= numberOfDays;
}

var ppx_printed_query = "fragment expiryInfo on ExpiryInfo   {\nexpiresAt  \nactivePeriodLength  \n}\n";

function parse(value) {
  var value$1 = Js_json.decodeObject(value);
  var tmp;
  if (value$1 !== undefined) {
    var value$2 = Js_dict.get(value$1, "expiresAt");
    var field_expiresAt = value$2 !== undefined ? Core__Option.map(!(value$2 == null) ? value$2 : undefined, GraphQLConverter.Helpers.parseUTCDateFromFloat) : Js_exn.raiseError("Unexpected GraphQL query response");
    var value$3 = Js_dict.get(value$1, "activePeriodLength");
    var field_activePeriodLength = value$3 !== undefined && !(value$3 == null) ? value$3 : undefined;
    tmp = {
      expiresAt: field_expiresAt,
      activePeriodLength: field_activePeriodLength
    };
  } else {
    tmp = Js_exn.raiseError("Unexpected GraphQL query response");
  }
  var partial_arg = GraphQLConverter.Helpers.some(tmp.activePeriodLength)("activePeriodLength was None");
  var partial_arg$1 = GraphQLConverter.Helpers.someOk(tmp.expiresAt, "expiresAt was None");
  var partial_arg$2 = function (param) {
    return GraphQLConverter.Helpers.flatMap((function (param) {
                  return GraphQLConverter.Helpers.source(tmp, param);
                }), partial_arg$1, param);
  };
  return GraphQLConverter.Helpers.run(function (expiresAt, activePeriodLength) {
                return {
                        expiresAt: expiresAt,
                        activePeriodLength: activePeriodLength
                      };
              })(function (param) {
              return GraphQLConverter.Helpers.flatMap(partial_arg$2, partial_arg, param);
            });
}

function ret_type(f) {
  return {};
}

var MT_Ret = {};

var ExpiryInfo = {
  ppx_printed_query: ppx_printed_query,
  query: ppx_printed_query,
  parse: parse,
  name: "expiryInfo",
  ret_type: ret_type,
  MT_Ret: MT_Ret
};

var Fragment = ExpiryInfo;

exports.expiresAt = expiresAt;
exports.activePeriodLength = activePeriodLength;
exports.make = make;
exports.daysToExpiration = daysToExpiration;
exports.aboutToExpire = aboutToExpire;
exports.Fragment = Fragment;
/* UTCDate Not a pure module */
